<template>
  <div>
    <!-- Filters -->
    <the-search-filter
      i18nPrefix="templates"
      :loading="state.loading"
      :searchFilter="state.searchFilter"
      @clear="clearSearchFilter()"
      @filterInput="param => updateInput(param)"
      @search="() => buildFilterParams()"
    />
    
    <templates-table
      :params="state.fetchParams"
      :key="state.templatesTableKey"
    />
  </div>
</template>
  
  <script setup>
  import { reactive, onMounted, ref, watchEffect } from "vue";
  import TheSearchFilter from "@/components/TheSearchFilter.vue";
  import TemplatesTable from "@/components/templates/TemplatesTable.vue";
  import { callFetchProducts } from "@/api/licensing";
  
  const options = ref([]);
  
  const state = reactive({
    templatesTableKey: 1,
    fetchParams: {
      count: 10,
      page: 1
    },
    searchFilter: {
      productId: {
        value: undefined,
        acceptedValues: []
      }
    },
    loading: false
  });
  
  watchEffect(() => {
    state.searchFilter.productId.acceptedValues = options.value.map(option => ({
      text: option.name,
      value: option.id
    }));
  });
  
  async function logProductDetails() {
    try {
      const productResponse = await callFetchProducts();
      options.value = productResponse.data.items;
    } catch (error) {
      console.error("Failed to fetch products:", error);
    }
  }
  
  async function clearSearchFilter() {
  
    Object.keys(state.searchFilter).forEach(key => {
      state.searchFilter[key].value = undefined;
    });
    await logProductDetails();
    buildFilterParams();
  }
  
  function updateInput(param) {
    const paramKey = param.inputKey;
    const paramValue = param.value;
    state.searchFilter[paramKey].value = paramValue;
  }
  
  async function buildFilterParams() {
    state.loading = true;
  
    Object.keys(state.searchFilter).forEach(key => {
      if (key === "productId") {
        state.fetchParams[key] = state.searchFilter.productId.value?.value;
      } else {
        state.fetchParams[key] = state.searchFilter[key].value;
      }
    });
  
    updateTableKey();
    state.loading = false;
  }
  
  const updateTableKey = () => {
    state.templatesTableKey = 3 - state.templatesTableKey;
  };
  
  onMounted(async () => {
    await logProductDetails();
    await buildFilterParams(); // Ensure initial load with data
  });
  </script>
  